var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"text-primary"},[_vm._v("Gestione del Personale ")]),_c('b-breadcrumb',{staticClass:"ml-2"},[_c('b-breadcrumb-item',[_c('feather-icon',{attrs:{"icon":"HomeIcon","scale":"1.25","shift-v":"1.25"}})],1),_c('b-breadcrumb-item',{on:{"click":function($event){return _vm.$router.push('/suppliers/list')}}},[_vm._v(" Fornitori")]),_c('b-breadcrumb-item',{attrs:{"active":""},domProps:{"innerHTML":_vm._s(_vm.$route.params.mode == 'edit' ? 'Modifica Fornitore' : 'Dettaglio Fornitore')}})],1)],1)]},proxy:true}])},[_c('div',{staticClass:"mt-2"},[_c('h2',{staticClass:"text-primary-light",domProps:{"innerHTML":_vm._s(_vm.$route.params.mode == 'edit' ? 'Modifica Fornitore' : 'Dettaglio Fornitore')}}),_c('validation-observer',{ref:"validationData"},[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Ragione Sociale","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Ragione Sociale"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"readonly":_vm.$route.params.mode != 'edit'},model:{value:(_vm.supplier.supplier_company),callback:function ($$v) {_vm.$set(_vm.supplier, "supplier_company", $$v)},expression:"supplier.supplier_company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Indirizzo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Indirizzo"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"readonly":_vm.$route.params.mode != 'edit'},model:{value:(_vm.supplier.supplier_address),callback:function ($$v) {_vm.$set(_vm.supplier, "supplier_address", $$v)},expression:"supplier.supplier_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Nazione","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nazione"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"readonly":_vm.$route.params.mode != 'edit'},model:{value:(_vm.supplier.supplier_nation),callback:function ($$v) {_vm.$set(_vm.supplier, "supplier_nation", $$v)},expression:"supplier.supplier_nation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Città","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Città"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"readonly":_vm.$route.params.mode != 'edit'},model:{value:(_vm.supplier.supplier_city),callback:function ($$v) {_vm.$set(_vm.supplier, "supplier_city", $$v)},expression:"supplier.supplier_city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Provincia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Provincia"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"readonly":_vm.$route.params.mode != 'edit'},model:{value:(_vm.supplier.supplier_province),callback:function ($$v) {_vm.$set(_vm.supplier, "supplier_province", $$v)},expression:"supplier.supplier_province"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"CAP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CAP"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"readonly":_vm.$route.params.mode != 'edit'},model:{value:(_vm.supplier.supplier_zipcode),callback:function ($$v) {_vm.$set(_vm.supplier, "supplier_zipcode", $$v)},expression:"supplier.supplier_zipcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Partita IVA","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Partita IVA"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"readonly":_vm.$route.params.mode != 'edit',"type":"number"},model:{value:(_vm.supplier.supplier_vat),callback:function ($$v) {_vm.$set(_vm.supplier, "supplier_vat", $$v)},expression:"supplier.supplier_vat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"readonly":_vm.$route.params.mode != 'edit',"type":"number"},model:{value:(_vm.supplier.supplier_email),callback:function ($$v) {_vm.$set(_vm.supplier, "supplier_email", $$v)},expression:"supplier.supplier_email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Telefono Fisso","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Telefono Fisso"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"readonly":_vm.$route.params.mode != 'edit'},model:{value:(_vm.supplier.supplier_phone),callback:function ($$v) {_vm.$set(_vm.supplier, "supplier_phone", $$v)},expression:"supplier.supplier_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Telefono Cellulare","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Telefono Cellulare"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"readonly":_vm.$route.params.mode != 'edit'},model:{value:(_vm.supplier.supplier_mobile_phone),callback:function ($$v) {_vm.$set(_vm.supplier, "supplier_mobile_phone", $$v)},expression:"supplier.supplier_mobile_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),(_vm.$route.params.mode == 'edit')?_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"4"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validationData}},[_vm._v(" Salva Modifiche ")])],1)],1):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }