<template>
  <section>
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Gestione del Personale </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item @click="$router.push('/suppliers/list')">
              Fornitori</b-breadcrumb-item
            >
            <b-breadcrumb-item active v-html="$route.params.mode == 'edit' ? 'Modifica Fornitore' : 'Dettaglio Fornitore'">
             </b-breadcrumb-item
            >
          </b-breadcrumb>
        </div>
      </template>

      <div class="mt-2">
        <h2 class="text-primary-light" v-html="$route.params.mode == 'edit' ? 'Modifica Fornitore' : 'Dettaglio Fornitore'"></h2>

        <validation-observer ref="validationData">
          <b-row class="mt-2">
            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="Ragione Sociale"
                rules="required"
              >
                <b-form-group label="Ragione Sociale">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    :readonly="$route.params.mode != 'edit'"
                    v-model="supplier.supplier_company"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <hr>

          <b-row>

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Indirizzo"
                rules="required"
              >
                <b-form-group label="Indirizzo">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    :readonly="$route.params.mode != 'edit'"
                    v-model="supplier.supplier_address"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Nazione"
                rules="required"
              >
                <b-form-group label="Nazione">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    :readonly="$route.params.mode != 'edit'"
                    v-model="supplier.supplier_nation"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Città"
                rules="required"
              >
                <b-form-group label="Città">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    :readonly="$route.params.mode != 'edit'"
                    v-model="supplier.supplier_city"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Provincia"
                rules="required"
              >
                <b-form-group label="Provincia">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    :readonly="$route.params.mode != 'edit'"
                    v-model="supplier.supplier_province"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="CAP"
                rules="required"
              >
                <b-form-group label="CAP">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    :readonly="$route.params.mode != 'edit'"
                    v-model="supplier.supplier_zipcode"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <hr>

          <b-row>

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Partita IVA"
                rules="required"
              >
                <b-form-group label="Partita IVA">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    :readonly="$route.params.mode != 'edit'"
                    type="number"
                    v-model="supplier.supplier_vat"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-group label="Email">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    :readonly="$route.params.mode != 'edit'"
                    type="number"
                    v-model="supplier.supplier_email"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Telefono Fisso"
                rules="required"
              >
                <b-form-group label="Telefono Fisso">
                  <b-form-input :state="errors.length > 0 ? false : null" :readonly="$route.params.mode != 'edit'" v-model="supplier.supplier_phone"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Telefono Cellulare"
                rules="required"
              >
                <b-form-group label="Telefono Cellulare">
                  <b-form-input :state="errors.length > 0 ? false : null" :readonly="$route.params.mode != 'edit'" v-model="supplier.supplier_mobile_phone"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

        </validation-observer>

        <b-row class="mt-2" v-if="$route.params.mode == 'edit'">
          <b-col md="4">
            <b-button variant="primary" @click="validationData">
              Salva Modifiche
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BSpinner,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";


import vSelect from "vue-select";

import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, email } from "@validations";

import { Requests } from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BSpinner,
    BFormGroup,
    BFormInput,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },

  data: () => ({

    supplier: {},  

    locations: [],

    //Helpers
    edit: false,
    loading: false,
    required,
    email
  }),

  created() {
    localize('it'); 

    this.getSupplier();
  },

  methods: {
    validationData() {
      this.$refs.validationData.validate().then((success) => {
        if (success) {
          this.addAccount();
        }
      });
    },

    async getSupplier() {
      this.loading = true;

      let request_data = new FormData();

      request_data.append('id_supplier', this.$route.params.id);

      try {
        const response = await Requests.getSupplierDetails(request_data)
        this.supplier = response[0].db_supplier_details.payload;
      } catch(err) {
        console.debug(err)
      }

      this.loading = false;
    },

    async addAccount() {
      this.loading = true;

      let request_data = new FormData;

      request_data.append('id_roles', 5)
      request_data.append('username', this.account.username);
      request_data.append('password', this.account.password);
      request_data.append('level', 5);
      request_data.append('nome', this.account.nome);
      request_data.append('cognome', this.account.cognome);
      request_data.append('azienda', this.account.azienda);
      request_data.append('ruolo', this.account.ruolo);
      request_data.append('telefono_interno', this.account.telefono_interno);
      request_data.append('email', this.account.username);
      request_data.append('ubicazione', this.account.ubicazione);
      request_data.append('ui_language', 'IT');

      try {
        const response = await Requests.addAccount(request_data);
        console.debug(response);
      } catch(err) {
        console.debug(err)
      }

      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
